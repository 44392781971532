@import "./../_Shared/colors";

html,
body,
#root {
  height: 100%;
  padding: 0 !important;
  background-color: #151515;
  overflow-wrap: break-word;
}

a:-webkit-any-link {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a {
  color: $blue_darker;
}

p {
  margin: 20px 0;
}
