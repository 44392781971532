@import "./colors";

.container {
  color: $aqua;
  padding: 15px 25px;
  font-size: 20px;

  &:hover {
    background-color: $aqua;
    color: black;
    width: fit-content;
  }
}
