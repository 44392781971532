// grey
$grey_h: #f1f1f1;
$grey_50: #fafafa;
$grey_200: #eeeeee;
$grey_250: #e1e1e1;
$grey_300: #e0e0e0;
$grey_400: #bdbdbd;
$grey_500: #9e9e9e;
$grey_600: #757575;
$grey_700: #616161;
$grey_750: #5f6368;
$grey_darker: #5f6367;
$light_grey: #dadce0;
$grey: grey;

// blue
$light_blue: #4285f426;
$blue: #1a73e8;
$blue_darker: #1967d2;
$aqua: #7fdbdb;

// red
$error: #d15454;
$red_400: #ef5350;

// amber
$amber_400: #ffca28;
$yellow: yellow;

// green
$light_green_400: #9ccc65;

// pink
$pink_600: #d81b60;
