.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  :global {
    .MuiCircularProgress-root {
      color: white;
    }
  }
}
