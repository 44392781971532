@import "./../_Shared/colors";

.container {
  height: 100%;

  .logo {
    margin-top: 60px;

    .img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    display: flex;
    margin: 0 30px;

    @media only screen and (max-width: 600px) {
      margin: 0 15px;
    }

    .main {
      width: 100%;

      p {
        color: white;
      }
    }
  }
}
